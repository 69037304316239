interface QueryParamsObject {
  promoCode: string;
  bundleCode: string;
  simulationId: string;
  utm_campaign: string;
  utm_source: string;
  utm_medium: string;
  utm_content: string;
  utm_term: string;
  gclid: string;
  dclid: string;
  sponsorReference: string;
}

export class QueryParamsMapperService {
  static getProxyQueryParams(): URLSearchParams {
    return new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: string) => searchParams.get(prop),
    });
  }

  static getQueryParamsObject(): QueryParamsObject {
    return QueryParamsMapperService.toQueryParamsObject(QueryParamsMapperService.getProxyQueryParams());
  }

  static toQueryParamsObject(params: URLSearchParams): QueryParamsObject {
    return {
      promoCode: params['promoCode'] || params['promocode'],
      bundleCode: params['bundleCode'] || params['bundlecode'],
      simulationId: params['simulationId'],
      utm_campaign: params['utm_campaign'],
      utm_source: params['utm_source'],
      utm_medium: params['utm_medium'],
      utm_content: params['utm_content'],
      utm_term: params['utm_term'],
      gclid: params['gclid'],
      dclid: params['dclid'],
      sponsorReference: params['sponsorReference'],
    };
  }

  static getQueryParam(value: string): string {
    const params = QueryParamsMapperService.getProxyQueryParams();

    return params[value];
  }
}
