<ng-container *ngIf="!isMobile">
  <ul class="nav-tabs tabs" [ngClass]="{ 'tabs--filled': !outlined, 'tabs--outlined': outlined }">
    <ng-container *ngFor="let option of options">
      <li
        (click)="active !== option && switch(option)"
        (keydown)="active !== option && switch(option)"
        class="tab__item"
        [class.active]="active === option"
      >
        <span>
          {{ translationPrefix + (option | lowercase) | translate }}
        </span>
      </li>
    </ng-container>
  </ul>
</ng-container>
