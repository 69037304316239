<div
  [ngClass]="{ 'p-10 pt-4': itemNumber > 3 }"
  class="flex justify-center items-center border-test rounded-3xl center-item cursor-pointer"
  (click)="addClicked.emit()"
  (keydown)="addClicked.emit()"
>
  <div class="flex flex-col">
    <div class="text-primary font-bold flex px-8 mt-6 items-center justify-center">
      <img class="mr-4" src="/assets/img/plus-ico.svg" alt="" />{{
        'pages.multisite.siteOverview.addSiteArea.btnLabel' | translate
      }}
    </div>
    <div class="text-grey-dark60 text-xs text-center m-6">
      <!-- We have in row 3 sites/items and when this -->
      <!--  add site component is last position in row we display different info text -->
      <ng-container *ngIf="itemNumber > 3 && itemNumber % 3; else defaultInfo">
        <span>{{ 'pages.multisite.siteOverview.addSiteArea.info' | translate }}</span>
        <span class="text-primary">{{ 'pages.multisite.siteOverview.addSiteArea.infoNumber' | translate }}</span>
      </ng-container>
      <ng-template #defaultInfo>
        <span>{{ 'pages.multisite.siteOverview.addSiteArea.infoShort' | translate }}</span>
      </ng-template>
    </div>
  </div>
</div>
