<div class="contact">
  <p
    class="contact-telephone"
    (click)="onPhoneClick()"
    (keydown)="onPhoneClick()"
    [innerHTML]="'header.' + type + '.openingDays' | translate"
  ></p>
  <div class="contact-timetables">
    <div class="desktopOnly">
      <p [innerHTML]="'header.' + type + '.openingHours.desktop.week' | translate"></p>
    </div>
    <div class="mobileOnly">
      <p>
        {{ 'header.' + type + '.openingHours.mobile.week' | translate }}
      </p>
    </div>
  </div>
</div>
