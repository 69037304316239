import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  @Input() text: string;
  @Input() label: string;
  @Input() params: object;
  @Input() position: 'right' | 'left' = 'right';
  @Input() verticalPosition: 'middle' | 'top' | 'bottom' = 'middle';

  showLongerText: boolean;
  readonly maxLengthText = 150;

  constructor(private readonly translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.get(this.text, this.params).subscribe((translatedText: string) => {
      this.showLongerText = translatedText.length > this.maxLengthText;
    });
  }
}
