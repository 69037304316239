import { Component, Input } from '@angular/core';
import { QuestionFacade } from '../../../facade/question.facade';
import { Type } from '../../../state/state';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  @Input() type: Type;
  readonly Type = Type;
  constructor(private readonly questionFacade: QuestionFacade) {}
  onPhoneClick(): void {
    this.questionFacade.onPhoneClicked();
  }
}
