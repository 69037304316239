<div class="lang-switcher">
  <span href="#" class="quickLinks__link">
    <span class="languageSwitcher">
      <span class="languageSwitcher__currentLanguage">
        <span class="languageSwitcher__lang">
          {{ language }}
          <img src="./assets/img/arrow-bottom.svg" class="arrow-down" alt="" />
        </span>
        <span
          class="languageSwitcher__lang"
          (click)="changeLanguage('nl')"
          (keydown)="changeLanguage('nl')"
          *ngIf="language !== 'nl'"
        >
          {{ 'common.lang.nl' | translate }}
        </span>
        <span
          class="languageSwitcher__lang"
          (click)="changeLanguage('fr')"
          (keydown)="changeLanguage('fr')"
          *ngIf="language !== 'fr'"
        >
          {{ 'common.lang.fr' | translate }}
        </span>
      </span>
    </span>
  </span>
</div>
