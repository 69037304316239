import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fadeInFadeOut } from '../../../shared/animations';
import { Observable } from 'rxjs';
import { QuestionFacade } from '../../../core/facade/question.facade';
import { DatePipe } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { QueryParamsMapperService } from 'src/app/core/facade/mapper/query-params-mapper.service';

@Component({
  selector: 'app-results-b2c',
  templateUrl: './results-b2c.component.html',
  styleUrls: ['./results-b2c.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInFadeOut],
})
export class ResultsB2cComponent implements OnInit {
  isBrussels$ = new Observable<boolean>();
  currentDate: { value: string | null };
  load = true;
  lang$: Observable<string>;
  showFriendReferalPromoAlert = false;

  @ViewChild('moreInfoAnchor') moreInfoAnchor: ElementRef;
  constructor(
    private readonly questionFacade: QuestionFacade,
    private readonly datePipe: DatePipe,
    private readonly translateService: TranslateService,
  ) {
    this.currentDate = { value: this.datePipe.transform(Date.now(), 'dd/MM/yyyy') };
    this.lang$ = this.translateService.onLangChange.pipe(
      map((lang) => lang.lang),
      startWith(this.translateService.currentLang),
    );
  }

  ngOnInit(): void {
    const queryParams = QueryParamsMapperService.getQueryParamsObject();
    this.showFriendReferalPromoAlert = !!queryParams?.sponsorReference;
    // if query params contain bundleCode value redirect to OLREG directly
    if (queryParams?.bundleCode) {
      this.questionFacade.redirectFromWebsiteToOlreg(queryParams.bundleCode);
    } else {
      this.questionFacade.runSimulation().subscribe(() => {
        this.load = false;
      });
      this.isBrussels$ = this.questionFacade.isBrussels();
    }
  }

  onGoToOlreg(code: string, totalAmount: number): void {
    this.questionFacade.trackAndGoToOlreg(code, totalAmount);
  }

  scrollToMoreInfos(): void {
    this.moreInfoAnchor.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
