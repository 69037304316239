export enum BelgiumRegions {
  FLANDERS = 'FLANDERS',
  WALLONIA = 'WALLONIA',
  BRUSSELS = 'BRUSSELS',
}

export class ZipCodeService {
  static isFlanders(zipCode: number): boolean {
    return BelgiumRegions.FLANDERS === this.getRegion(zipCode);
  }

  static isBrussels(zipCode: number): boolean {
    return BelgiumRegions.BRUSSELS === this.getRegion(zipCode);
  }

  static isWallonia(zipCode: number): boolean {
    return BelgiumRegions.WALLONIA === this.getRegion(zipCode);
  }

  /* eslint-disable @typescript-eslint/no-magic-numbers */
  private static getRegion(zipCode: number): string {
    if (zipCode >= 1000 && zipCode <= 1212) {
      return BelgiumRegions.BRUSSELS;
    } else if ((zipCode >= 1500 && zipCode <= 3999) || (zipCode >= 8000 && zipCode <= 9999)) {
      return BelgiumRegions.FLANDERS;
    } else {
      return BelgiumRegions.WALLONIA;
    }
  }
}
