import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { InputRadioConfig } from '../../shared/custom-form-control/input-radio-wrapper/input-radio-wrapper.component';
import { QuestionFacade } from '../../core/facade/question.facade';
import { MeterType } from '../../core/state/state';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { CompleteObservables } from 'src/app/shared/complete-observables';

@Component({
  selector: 'app-meter-type',
  templateUrl: './meter-type.component.html',
  styleUrls: ['./meter-type.component.css'],
})
export class MeterTypeComponent extends CompleteObservables implements OnInit {
  readonly MeterType = MeterType;
  options: InputRadioConfig[] = [
    {
      id: MeterType.MONO,
      value: MeterType.MONO,
      image: './assets/img/mono.svg',
      caption: 'pages.meterType.formElements.mono.label',
    },
    {
      id: MeterType.DUAL,
      value: MeterType.DUAL,
      image: './assets/img/dual.svg',
      caption: 'pages.meterType.formElements.dual.label',
    },
  ];
  formGroup: UntypedFormGroup;

  constructor(
    private readonly questionFacade: QuestionFacade,
    private readonly fb: UntypedFormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.questionFacade
      .findSiteData((site) => site.meterType)
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.questionFacade.findSiteData((site) => site.exclusiveNight)))
      .subscribe(
        ([meterType, exclusiveNigh]) =>
          (this.formGroup = this.fb.group({
            meterType: [meterType, [Validators.required]],
            exclusiveNight: [exclusiveNigh || false],
          })),
      );
  }

  onPreviousClicked(): void {
    this.questionFacade.previous();
  }

  onNextClicked(): void {
    this.questionFacade.updateSiteData({ ...this.formGroup.value });
    this.questionFacade.next();
  }
}
