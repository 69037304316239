<div class="navigation-container">
  <div
    (click)="previousClicked.emit()"
    (keydown)="previousClicked.emit()"
    *ngIf="!questionFacade.isFirstQuestion()"
    id="showPrevious"
    data-cy="navigate-to-previous"
    class="btn navigation-button navigation-previous"
  >
    <img src="./assets/img/arrow-left.svg" [alt]="'general.navigation.backArrowAlt' | translate" />
    <span>{{ 'navigation.previous' | translate }}</span>
  </div>

  <button
    *ngIf="(stepValidity && !questionFacade.isLastQuestion()) || disableNextStyle"
    (click)="nextClicked.emit()"
    [disabled]="!stepValidity"
    id="showNext"
    class="btn btn-primary navigation--next w-full md:w-auto"
    type="button"
    data-cy="next"
  >
    {{ nextLabel | translate }}
  </button>
</div>
