<div class="flex items-center max:w-full h-fit border-2 rounded-2xl border-yellow bg-yellow/20 px-10 py-6">
  <img
    class="flex p-2 ml-0 mr-4 md:ml-6 md:mr-8 lg:mr-16 rounded-full bg-yellow"
    src="./assets/img/promo.png"
    alt="Promo"
  />
  <div class="mx-4">
    <span class="font-medium" [innerHTML]="title | translate"></span>
    <span [innerHTML]="content | translate"></span>
  </div>
</div>
