import { maxConsumptionReached, NumberOfSite, State } from '../../state/state';

export abstract class Question {
  abstract displayCondition(state: State): boolean;

  abstract getUrl(): string;

  abstract getNlTranslatedUrl(): string;

  abstract getFrTranslatedUrl(): string;

  abstract getDisplayTackingDataValue(state: State): string;

  getNext(state: State, orderedQuestionsConfig: Question[]): Question {
    const currentIndex = this.findIndex(orderedQuestionsConfig);
    if (currentIndex + 1 === orderedQuestionsConfig.length) {
      return undefined;
    }
    const next = orderedQuestionsConfig[currentIndex + 1];
    return next.displayCondition(state) ? next : next.getNext(state, orderedQuestionsConfig);
  }

  getPrevious(state: State, orderedQuestionsConfig: Question[]): Question {
    const currentIndex = this.findIndex(orderedQuestionsConfig);
    if (0 === currentIndex) {
      return undefined;
    }
    const previous = orderedQuestionsConfig[currentIndex - 1];
    return previous.displayCondition(state) ? previous : previous.getPrevious(state, orderedQuestionsConfig);
  }

  private findIndex(orderedQuestionsConfig: Question[]): number {
    return orderedQuestionsConfig.findIndex((question) => question.getUrl() === this.getUrl());
  }
}

export abstract class ZipCode extends Question {
  abstract displayCondition(state: State): boolean;

  getUrl(): string {
    return '/zipCode';
  }

  getDisplayTackingDataValue(): string {
    return 'location';
  }
  getFrTranslatedUrl(): string {
    return 'code-postal';
  }

  getNlTranslatedUrl(): string {
    return 'postcode';
  }
}

export abstract class Energy extends Question {
  abstract displayCondition(state: State): boolean;

  getUrl(): string {
    return '/energy';
  }

  getDisplayTackingDataValue(): string {
    return 'energy-type';
  }
  getFrTranslatedUrl(): string {
    return 'choix-energie';
  }

  getNlTranslatedUrl(): string {
    return 'keuze-energie';
  }
}

export abstract class KnowConsumption extends Question {
  abstract displayCondition(state: State): boolean;

  getUrl(): string {
    return '/know-consumptions';
  }

  getDisplayTackingDataValue(): string {
    return 'consumption-know';
  }

  getFrTranslatedUrl(): string {
    return 'ma-consommation';
  }

  getNlTranslatedUrl(): string {
    return 'mijn-verbruik';
  }
}

export abstract class HasSolarPanels extends Question {
  abstract displayCondition(state: State): boolean;

  getUrl(): string {
    return '/solar-panels';
  }

  getDisplayTackingDataValue(): string {
    return 'photovoltaic';
  }

  getFrTranslatedUrl(): string {
    return 'panneaux-photovoltaiques';
  }

  getNlTranslatedUrl(): string {
    return 'zonnepanelen';
  }
}

export abstract class CertificationPV extends Question {
  abstract displayCondition(state: State): boolean;

  getUrl(): string {
    return '/pv-certification';
  }

  getDisplayTackingDataValue(): string {
    return 'pv-certification';
  }

  getFrTranslatedUrl(): string {
    return 'pv-certification';
  }

  getNlTranslatedUrl(): string {
    return 'pv-certificaat';
  }
}

export abstract class SolarPanelKva extends Question {
  abstract displayCondition(state: State): boolean;

  getUrl(): string {
    return '/solar-panel-kva';
  }

  getDisplayTackingDataValue(): string {
    return 'photovoltaic-production';
  }
  getFrTranslatedUrl(): string {
    return 'ma-production';
  }

  getNlTranslatedUrl(): string {
    return 'mijn-productie';
  }
}

export abstract class SmartMeter extends Question {
  abstract displayCondition(state: State): boolean;

  getUrl(): string {
    return '/smart-meter';
  }

  getDisplayTackingDataValue(): string {
    return 'smart-meter-type';
  }

  getFrTranslatedUrl(): string {
    return 'compteur-intelligent';
  }

  getNlTranslatedUrl(): string {
    return 'digitale-meter';
  }
}

export abstract class HasElectricVehicle extends Question {
  abstract displayCondition(state: State): boolean;

  getUrl(): string {
    return '/has-electric-vehicle';
  }

  getDisplayTackingDataValue(): string {
    return 'electric-vehicle';
  }

  getFrTranslatedUrl(): string {
    return 'vehicule-electrique';
  }

  getNlTranslatedUrl(): string {
    return 'elektrische-auto';
  }
}

export abstract class AveragePeak extends Question {
  abstract displayCondition(state: State): boolean;

  getUrl(): string {
    return '/average-peak';
  }

  getDisplayTackingDataValue(): string {
    return 'average-peak';
  }
  getFrTranslatedUrl(): string {
    return 'pic-moyen';
  }

  getNlTranslatedUrl(): string {
    return 'gemiddelde-piek';
  }
}

export abstract class MeterType extends Question {
  abstract displayCondition(state: State): boolean;

  getUrl(): string {
    return '/meterType';
  }

  getDisplayTackingDataValue(): string {
    return 'meter-type';
  }
  getFrTranslatedUrl(): string {
    return 'mon-compteur';
  }

  getNlTranslatedUrl(): string {
    return 'mijn-meter';
  }
}

export abstract class ConsumptionHelp extends Question {
  abstract displayCondition(state: State): boolean;

  getUrl(): string {
    return '/consumptions-help';
  }

  getDisplayTackingDataValue(): string {
    return 'company-type';
  }
  getFrTranslatedUrl(): string {
    return 'aide-consommation';
  }

  getNlTranslatedUrl(): string {
    return 'consumptie-hulp';
  }
}

export abstract class Consumptions extends Question {
  abstract displayCondition(state: State): boolean;

  getUrl(): string {
    return '/consumptions';
  }

  getDisplayTackingDataValue(): string {
    return 'consumption-details';
  }
  getFrTranslatedUrl(): string {
    return 'ma-consommation-annuelle';
  }

  getNlTranslatedUrl(): string {
    return 'mijn-jaarlijks-verbruik';
  }
}

export class Result extends Question {
  displayCondition(state: State): boolean {
    return state.numberOfSites !== NumberOfSite.MORE_THAN_8 && !maxConsumptionReached(state) && state.sites.length <= 8;
  }

  getUrl(): string {
    return '/result';
  }

  getDisplayTackingDataValue(): string {
    return 'sim-estimateOffer';
  }
  getFrTranslatedUrl(): string {
    return 'resultat';
  }

  getNlTranslatedUrl(): string {
    return 'resultaat';
  }
}
