<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto" *ngIf="formControl">
  <app-title title="pages.averagePeak.title" subtitle="pages.averagePeak.subtitle"></app-title>
  <div class="mb-2 center">
    <app-form-card icon="averagePeak">
      <app-input-text [formControl]="formControl" [inputTextConfig]="STEP_CONFIG"> </app-input-text>
    </app-form-card>
  </div>
  <p class="list__dontKnow cursor-pointer" (click)="defaultAveragePeak()" (keydown)="defaultAveragePeak()">
    <span tabindex="2">
      <span>{{ 'pages.averagePeak.averageTitle' | translate }}</span>
      <span class="list__dontKnow--subtitle" [innerHTML]="'pages.averagePeak.formElements.power.average' | translate">
      </span>
    </span>
  </p>
  <app-navigation
    [stepValidity]="formControl.valid"
    [disableNextStyle]="true"
    [automaticTransition]="false"
    (nextClicked)="onNextClicked()"
    (previousClicked)="onPreviousClicked()"
  >
  </app-navigation>
</div>
