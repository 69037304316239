<div>
  <div class="relative mx-0">
    <app-site-title
      [siteDisplay]="siteDisplay"
      [fullRadius]="false"
      [displayChevron]="false"
      [numberToDisplay]="siteNumber"
    ></app-site-title>
  </div>
  <div class="bg-white rounded-b-4xl box-shadow-small px-4 pt-4">
    <app-rename-site (formReady)="onFormReady($event)"></app-rename-site>
    <div class="site-buttons">
      <div class="flex border-solid border-gray border-t-2 items-center justify-center">
        <div
          class="text-primary font-bold cursor-pointer flex px-8 mt-6 items-center justify-center border-solid border-gray border-r-2"
          (click)="onSaveSiteName()"
          (keydown)="onSaveSiteName()"
        >
          <img class="mr-4" src="./assets/img/save-ico.svg" alt="" />{{
            'pages.result.changeNamePopUp.save' | translate
          }}
        </div>
        <div
          class="text-primary font-bold cursor-pointer flex px-8 mt-6 items-center justify-center"
          (click)="cancel.emit()"
          (keydown)="cancel.emit()"
        >
          <img class="mr-4" src="./assets/img/close-ico.svg" alt="" />
          {{ 'pages.result.changeNamePopUp.cancel' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
