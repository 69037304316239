import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export function i18nInitializer(i18nService: I18nService): () => Promise<boolean> {
  return (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {
        i18nService.init();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
}

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  language: string;
  constructor(
    public translate: TranslateService,
    @Inject('LANGUAGES') readonly languages: string[],
    @Inject('DEFAULT_LANGUAGE') readonly defaultLanguage: string,
  ) {
    this.language = this.defaultLanguage;
  }

  init(): void {
    this.translate.addLangs(this.languages);
    this.translate.setDefaultLang(this.defaultLanguage);
    this.language = this.getLanguage();
    this.translate.use(this.language).subscribe();
  }

  setLanguage(lang: string): void {
    this.language = lang;
  }

  getLanguage(): string {
    // Check url language
    const urlLang = this.getUrlLanguage();
    if (urlLang) {
      return urlLang;
    }

    // check browserLanguage
    const browserLang = this.getBrowserLanguage();
    if (browserLang) {
      return browserLang;
    }

    // Return default
    return this.defaultLanguage;
  }

  getUrlLanguage(): string | undefined {
    // Check language at the beginning of the base path.
    const urlLang = window.location.pathname.split('/')[1];
    if (this.languages.find((el) => el === urlLang)) {
      return urlLang;
    }

    return undefined;
  }

  private getBrowserLanguage(): string {
    const browserLang = this.translate.getBrowserLang();
    if (this.languages.find((el) => el === browserLang)) {
      return browserLang;
    }
    return undefined;
  }
}
