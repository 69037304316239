import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { QuestionFacade } from 'src/app/core/facade/question.facade';
import { Site } from 'src/app/core/state/state';
import { SiteDisplay } from '../results/sites/sites.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-multisite',
  templateUrl: './multisite.component.html',
  styleUrls: ['./multisite.component.scss'],
})
export class MultisiteComponent {
  sites$: Observable<SiteDisplay[]>;
  siteWithRenameView: SiteDisplay;

  constructor(private readonly questionFacade: QuestionFacade) {
    this.sites$ = this.questionFacade.findData((data) => data.sites).pipe(map((sites) => this.toSiteDisplay(sites)));
  }

  onPreviousClicked(): void {
    this.questionFacade.previous();
  }

  onNextClicked(): void {
    this.questionFacade.next();
  }

  onAddClicked(): void {
    this.questionFacade.addSite();
  }

  onDeleteClicked(siteIndex: number): void {
    this.questionFacade.deleteSite(siteIndex);
  }

  onSaveClicked(value: SiteNameData): void {
    this.questionFacade.saveSiteName(value.siteIndex, value.name);
  }

  toSiteDisplay(sites: Site[]): SiteDisplay[] {
    return sites.map((site) => ({
      site: { ...site },
    }));
  }

  onSiteDetailsClicked(): void {
    this.questionFacade.sendSimulatorInteractionTrackingData('Delivery point info');
  }

  onSaveSiteName(newName: string, siteIndex: number): void {
    this.questionFacade.saveSiteName(siteIndex, newName);
    this.siteWithRenameView = null;
  }
}

export interface SiteNameData {
  siteIndex: number;
  name: string;
}
