import { Consumptions, Energy, MeterType, Site } from '../../state/state';

export class OlregB2bMapperService {
  static toOlregQueryParams(
    bundleCode: string,
    sites: Site[],
    promoCode: string,
    simulationId: string,
  ): URLSearchParams {
    const olregFormatSites = sites.map((site) => {
      const { ...siteCloned } = site;
      return {
        ...siteCloned,
        elecMeterInfos: {
          consumptions: this.mapElecConsumption(site.consumptions),
        },
        gasMeterInfos: {
          consumptions: this.mapGasConsumption(site.consumptions),
        },
        simulationId,
      };
    });

    const urlParameters = new URLSearchParams();
    urlParameters.append('comingFromTotalEnergiesSimulator', 'true');
    urlParameters.append('energy', this.getEnergyAllSites(sites));
    urlParameters.append('bundleCode', bundleCode);
    urlParameters.append('meterType', sites[0].meterType || MeterType.MONO);
    urlParameters.append('zipCode', String(sites[0].zipCode));
    urlParameters.append('sites', JSON.stringify(olregFormatSites));
    if (promoCode) {
      urlParameters.append('promoCode', promoCode);
    }

    return urlParameters;
  }

  private static getEnergyAllSites(sites: Site[]): Energy {
    const hasElec = sites.some((site: Site) => site.energy != null && site.energy.includes(Energy.ELEC));
    const hasGas = sites.some((site: Site) => site.energy != null && site.energy.includes(Energy.GAS));
    if (hasElec && hasGas) {
      return Energy.ELEC_GAS;
    }
    return hasElec ? Energy.ELEC : Energy.GAS;
  }

  private static mapElecConsumption(consumptions: Consumptions): Partial<Consumptions> {
    return {
      ...(consumptions.consumptionHigh !== null && { consumptionHigh: consumptions.consumptionHigh }),
      ...(consumptions.consumptionLow !== null && { consumptionLow: consumptions.consumptionLow }),
      ...(consumptions.consumptionMono !== null && { consumptionMono: consumptions.consumptionMono }),
      ...(consumptions.consumptionExclusiveNight !== null && {
        consumptionExclusiveNight: consumptions.consumptionExclusiveNight,
      }),
      ...(consumptions.injectionHigh !== null && { injectionHigh: consumptions.injectionHigh }),
      ...(consumptions.injectionLow !== null && { injectionLow: consumptions.injectionLow }),
      ...(consumptions.injectionMono !== null && { injectionMono: consumptions.injectionMono }),
    };
  }

  private static mapGasConsumption(consumptions: Consumptions): { consumption: number } {
    return { consumption: consumptions.gasConsumption };
  }
}
