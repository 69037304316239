import { Component, Input } from '@angular/core';
import { CustomFormParentComponent } from '../custom-form-parent.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputRadioType } from '../input-radio/input-radio.component';

@Component({
  selector: 'app-input-radio-wrapper',
  templateUrl: './input-radio-wrapper.component.html',
  styleUrls: ['./input-radio-wrapper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputRadioWrapperComponent,
    },
  ],
})
export class InputRadioWrapperComponent extends CustomFormParentComponent<InputRadioConfig[]> {
  @Input() configurations: InputRadioConfig[];
  @Input() square = true;

  updateValue(value: InputRadioConfig[]): void {
    this.value = value;
  }
}

export interface InputRadioConfig {
  id: string;
  value: string | boolean;
  caption?: string;
  type?: InputRadioType;
  checked?: boolean;
  image?: string;
  desktopImage?: string;
  imageAltText?: string;
  trackingValue?: string;
}
