import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TypeDetectorService } from '../../core/service/type-detector.service';
import { toNumber } from '../../core/utils';

const totalConsumptionByType = {
  b2c: 50000,
  b2b: 100000,
};

export const elecMaxConsumptionsValidator: ValidatorFn = (group: UntypedFormGroup): ValidationErrors | null => {
  if (
    group.controls['consumptionHigh'].errors?.['pattern'] ||
    group.controls['consumptionLow'].errors?.['pattern'] ||
    group.controls['consumptionExclusiveNight'].errors?.['pattern']
  ) {
    return null;
  }
  const consumptionHigh = toNumber(group.controls['consumptionHigh'].value);
  const consumptionLow = toNumber(group.controls['consumptionLow'].value);
  const exclusiveNight = toNumber(group.controls['consumptionExclusiveNight'].value);
  const totalConsumption = consumptionHigh + consumptionLow + exclusiveNight;

  return totalConsumption <= totalConsumptionByType[TypeDetectorService.detectType()?.toLowerCase()]
    ? null
    : { maxElecConsumptionValidity: true };
};

export const gasMaxConsumptionsValidator: ValidatorFn = (group: UntypedFormGroup): ValidationErrors | null => {
  if (group.controls['gasConsumption'].errors?.pattern) {
    return null;
  }
  const totalConsumption = toNumber(group.controls['gasConsumption'].value);

  return totalConsumption <= totalConsumptionByType[TypeDetectorService.detectType()?.toLowerCase()]
    ? null
    : { maxGasConsumptionValidity: true };
};
