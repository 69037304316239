import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { InputRadioConfig } from '../../shared/custom-form-control/input-radio-wrapper/input-radio-wrapper.component';
import { QuestionFacade } from '../../core/facade/question.facade';
import { Energy } from '../../core/state/state';
import { takeUntil } from 'rxjs/operators';
import { CompleteObservables } from 'src/app/shared/complete-observables';

@Component({
  selector: 'app-energy',
  templateUrl: './energy.component.html',
  styleUrls: ['./energy.component.css'],
})
export class EnergyComponent extends CompleteObservables implements OnInit {
  options: InputRadioConfig[] = [
    {
      id: Energy.ELEC,
      value: Energy.ELEC,
      image: './assets/img/elec.svg',
      caption: 'pages.energy.formElements.elec.label',
    },
    {
      id: Energy.ELEC_GAS,
      value: Energy.ELEC_GAS,
      image: './assets/img/elec-gas.svg',
      caption: 'pages.energy.formElements.elecAndGas.label',
    },
    {
      id: Energy.GAS,
      value: Energy.GAS,
      image: './assets/img/gas.svg',
      caption: 'pages.energy.formElements.gas.label',
    },
  ];
  formControl: UntypedFormControl;

  constructor(private readonly questionFacade: QuestionFacade) {
    super();
  }

  ngOnInit(): void {
    this.questionFacade
      .findSiteData((site) => site.energy)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.formControl = new UntypedFormControl(value, Validators.required)));
  }

  onPreviousClicked(): void {
    this.questionFacade.previous();
  }

  onNextClicked(): void {
    this.questionFacade.updateSiteData({ energy: this.formControl.value });
    this.questionFacade.next();
  }
}
