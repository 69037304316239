import { Component, OnInit } from '@angular/core';
import { fadeInFadeOut } from '../../../shared/animations';
import { SiteDisplay } from '../sites/sites.component';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { QuestionFacade } from '../../../core/facade/question.facade';
import { switchMap } from 'rxjs/operators';
import { QueryParamsMapperService } from 'src/app/core/facade/mapper/query-params-mapper.service';

@Component({
  selector: 'app-results-b2b',
  templateUrl: './results-b2b.component.html',
  styleUrls: ['./results-b2b.component.scss'],
  animations: [fadeInFadeOut],
})
export class ResultsB2bComponent implements OnInit {
  isProsumerInWalloniaOrFlanders$ = new Observable<boolean>();
  isProsumerInBrussels$ = new Observable<boolean>();
  isBrussels$ = new Observable<boolean>();
  contactEmail = environment.CONTACT_EMAIL_B2B;
  currentDate: { value: string };
  load = true;
  sites$: Observable<SiteDisplay[]>;

  constructor(private readonly questionFacade: QuestionFacade) {}

  ngOnInit(): void {
    const queryParams = QueryParamsMapperService.getQueryParamsObject();
    // if query params contain bundleCode value redirect to OLREG directly
    if (queryParams?.bundleCode) {
      this.questionFacade.redirectFromWebsiteToOlreg(queryParams.bundleCode);
    } else {
      this.questionFacade.runSimulation().subscribe(() => (this.load = false));
      this.isProsumerInWalloniaOrFlanders$ = this.questionFacade.isProsumerInWalloniaOrFlanders();
      this.isProsumerInBrussels$ = this.questionFacade.isProsumerInBrussels();
      this.isBrussels$ = this.questionFacade.isBrussels();
      this.sites$ = this.questionFacade.runSimulation().pipe(
        switchMap(() =>
          this.questionFacade.findData((state) =>
            state.sites.map((site) => ({
              bundles: site.simulation,
              simulationId: state.simulationId,
              site: site,
            })),
          ),
        ),
      );
    }
  }

  onGoToOlreg(code: string, totalAmount: number): void {
    this.questionFacade.trackAndGoToOlreg(code, totalAmount);
  }
}
