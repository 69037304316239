import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss'],
})
export class FormCardComponent {
  private readonly defaultRange = {
    top: -10,
    left: 47,
  };

  @Input() icon: string;
  @Input() iconTopPercent = this.defaultRange.top;
  @Input() iconLeftPercent = this.defaultRange.left;
}
