import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as Icons from './icons';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss'],
})
export class IconsComponent implements OnInit {
  @Input() iconName: string;
  icons = Icons;
  icon: SafeHtml;

  constructor(private readonly sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (Object.prototype.hasOwnProperty.call(this.icons, this.iconName)) {
      this.icon = this.sanitizer.bypassSecurityTrustHtml(this.icons[this.iconName]);
    }
  }
}
