import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss'],
})
export class LangSwitcherComponent {
  @Input() language: string;
  @Output() langChanged = new EventEmitter<string>();

  changeLanguage(lang: string): void {
    this.langChanged.next(lang);
  }
}
