import { Component } from '@angular/core';
import { Card } from '../../shared/card/card.component';
import { environment } from 'src/environments/environment';
import { QuestionFacade } from '../../core/facade/question.facade';
import { TypeDetectorService } from '../../core/service/type-detector.service';
import { Type } from '../../core/state/state';

@Component({
  selector: 'app-too-much-sites',
  templateUrl: './too-much-sites.component.html',
})
export class TooMuchSitesComponent {
  contactTelCard: Card = new Card(
    `pages.contact.cards.tel.title`,
    `pages.contact.cards.tel.content`,
    null,
    null,
    null,
    false,
    'icon phone-icon',
  );

  isB2c = TypeDetectorService.detectType() === Type.B2C;
  contactMailCard: Card = new Card(
    `pages.contact.cards.mail.title`,
    `pages.contact.cards.mail.content`,
    null,
    null,
    null,
    false,
    'icon mail-icon',
    { param: this.isB2c ? environment.CONTACT_EMAIL_B2C : environment.CONTACT_EMAIL_B2B },
  );
  constructor(private readonly questionFacade: QuestionFacade) {}

  onPhoneClicked(): void {
    this.questionFacade.onPhoneClicked();
  }
  onPreviousClicked(): void {
    this.questionFacade.previous();
  }

  onNextClicked(): void {
    this.questionFacade.next();
  }

  onEmailClicked(): void {
    this.questionFacade.onEmailClicked();
  }
}
