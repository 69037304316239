import { Component, EventEmitter, Output } from '@angular/core';
import { Energy } from 'src/app/core/state/state';

@Component({
  selector: 'app-site-details-modal',
  templateUrl: './site-details-modal.component.html',
  styleUrls: ['./site-details-modal.component.scss'],
})
export class SiteDetailsModalComponent {
  @Output() closeDetail = new EventEmitter<boolean>();
  @Output() saveName = new EventEmitter<{ siteIndex: number; name: string }>();
  readonly Energy = Energy;

  closeSiteDetails(): void {
    this.closeDetail.emit();
  }
}
