import {
  trigger,
  transition,
  style,
  animate,
  query,
  animateChild,
  AnimationTriggerMetadata,
} from '@angular/animations';

export function routerAnimation(): AnimationTriggerMetadata {
  return trigger('routerAnimation', [
    // Previous, slide left to right to show left page
    transition(':decrement', [
      style({ position: 'relative' }),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            opacity: 1,
          }),
        ],
        { optional: true },
      ),
      query(':enter', [style({ left: '-100px', opacity: 0 })], { optional: true }),
      query(':leave', [style({ left: '0', opacity: 1 })], { optional: true }),
      query(':leave', [animate('400ms cubic-bezier(.25,.46,.45,.94)', style({ left: '100px', opacity: 0 }))], {
        optional: true,
      }),
      query(':enter', [animate('400ms cubic-bezier(.25,.46,.45,.94)', style({ left: '0%', opacity: 1 }))], {
        optional: true,
      }),
      query(':enter', animateChild(), { optional: true }),
    ]),

    // Next, slide right to left to show right page
    transition(':increment', [
      style({ position: 'relative' }),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            opacity: 1,
          }),
        ],
        { optional: true },
      ),
      query(':enter', [style({ left: '100px', opacity: 0 })], { optional: true }),
      query(':leave', [style({ left: '0', opacity: 1 })], { optional: true }),
      query(':leave', [animate('400ms cubic-bezier(.25,.46,.45,.94)', style({ left: '-100px', opacity: 0 }))], {
        optional: true,
      }),
      query(':enter', [animate('400ms cubic-bezier(.25,.46,.45,.94)', style({ left: '0%', opacity: 1 }))], {
        optional: true,
      }),
      query(':enter', animateChild(), { optional: true }),
    ]),
  ]);
}

export const fadeInFadeOut: AnimationTriggerMetadata = trigger('fadeInFadeOut', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]),
]);
