import { Component } from '@angular/core';
import { Card } from '../../../shared/card/card.component';
import { QuestionFacade } from '../../../core/facade/question.facade';

@Component({
  selector: 'app-dedicated-access-point',
  templateUrl: './dedicated-access-point.component.html',
  styleUrls: ['./dedicated-access-point.component.css'],
})
export class DedicatedAccessPointComponent {
  dedicatedAccessPoint: Card = new Card(
    `pages.contact.cards.tel.title`,
    `pages.contact.cards.tel.content`,
    'pages.thankyou.solarPanel.linkLabel',
    'pages.thankyou.solarPanel.linkURL',
    null,
    false,
    'icon phone-icon',
  );

  constructor(private readonly questionFacade: QuestionFacade) {}

  onPreviousClicked(): void {
    this.questionFacade.previous();
  }

  onNextClicked(): void {
    this.questionFacade.next();
  }
}
