<app-site-details [siteDisplay]="siteDisplay" [fullRoundedTitle]="false" [siteNumber]="siteNumber">
  <div class="flex border-solid border-gray border-t-2 items-center justify-center">
    <div
      class="text-primary font-bold cursor-pointer flex px-8 mt-6 items-center justify-center border-solid border-gray border-r-2"
      (click)="updateSiteName.emit()"
      (keydown)="updateSiteName.emit()"
    >
      <img class="mr-4" src="./assets/img/pen-ico.svg" alt="" />
      {{ 'pages.result.changeNamePopUp.renameLabel' | translate }}
    </div>
    <div
      class="text-primary font-bold cursor-pointer flex px-8 mt-6 items-center justify-center"
      (click)="deleteClicked.emit(siteDisplay)"
      (keydown)="deleteClicked.emit(siteDisplay)"
    >
      <img class="mr-4" src="./assets/img/trash-ico.svg" alt="" />
      {{ 'pages.multisite.siteOverview.delete' | translate }}
    </div>
  </div>
</app-site-details>
